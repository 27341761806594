<template>
  <div>
    <a-modal v-model:visible="visible" :title="title" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal" :okButtonProps="{ style: { display: 'none' }}" width="55vw">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <div v-if="record.category=='Mifang'||record.category=='Jingfang'" class="detailModal">
          <div><label>名称：</label><span>{{record?.name}}</span></div>
          <div><label>描述：</label><span>{{record?.description}}</span></div>
          <div><label>来源：</label><span>{{record?.source}}</span></div>
        </div>

        <div v-if="record.category=='药对'" class="detailModal">
          <div><label>名称：</label><span>{{record?.name}}</span></div>
          <div><label>首字母：</label><span>{{record?.nameInitial}}</span></div>
          <div><label>脏腑：</label><span>{{record?.zangfu}}</span></div>
          <div><label>脏腑影响：</label><span>{{record?.zangfuAffect}}</span></div>
          <div><label>性别：</label><span>{{record?.gender}}</span></div>
        </div>

        <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="id" :pagination="false" bordered>
          <template #title>
            <div class="thead">智能处方</div>
          </template>
          <template #name="{ record }">
            {{record?.prescription?.name}}
          </template>
          <template #operation="{ record }">
            <div class="flex_sa">
              <div class="editable-row-operations">
                <span>
                  <a v-if="record.category == '疾病穴位处方'||record.category == '症候穴位处方'" @click="editAcupoint(record)">编辑</a>
                  <a v-else @click="editPrescription(record)">编辑</a>
                </span>
              </div>
              <div class="editable-row-operations" v-if="record.category != 'Mifang'">
                <span>
                  <a-popconfirm v-if="data.length" title="是否确认删除" @confirm="onDelete(record)" okText="确认" cancelText="取消">
                    <a>删除</a>
                  </a-popconfirm>
                </span>
              </div>
            </div>
          </template>
        </a-table>

        <div style="text-align: center;margin-top: 20px;" v-if="record.category != 'Mifang'">
          <span style="font-weight: bold;">新增关联：</span>
          <prescriptionSelector v-model="prescription" :record="record" />
          <a-button @click="addToTable">添加</a-button>
        </div>

        <div v-if="record.category=='Jingfang'" style="text-align: center;margin-top: 20px;">
          <a-button type="primary" @click="visible3=true">添加处方</a-button>
        </div>
        <div v-else-if="record.category=='疾病穴位处方'||record.category=='症候穴位处方'" style="text-align: center;margin-top: 20px;">
          <a-button type="primary" @click="addAcupoint">添加穴位处方</a-button>
        </div>
      </a-form>
    </a-modal>
    <addPrescription v-model="visible3" @getData="callbackData" />
    <editPrescription v-model="visible4" :record="selectedRow" />
    <addAcupoint v-model="visible5" :record="addAcupointInfo" @getData="callbackData2" />
    <editAcupoint v-model="visible6" :record="selectedRow" />

  </div>
</template>

<script>
import { Detail, Add, Delete } from "/src/api/autoPrescription.js";
import addPrescription from '/src/components/prescription/addPrescription.vue';
import prescriptionSelector from '/src/components/selector/prescriptionSelector.vue';
import editPrescription from '/src/components/prescription/editPrescription.vue';
import addAcupoint from '/src/components/acupoint/addAcupoint.vue';
import editAcupoint from '/src/components/acupoint/editAcupoint.vue';

export default {
  components: {
    addPrescription,
    prescriptionSelector,
    editPrescription,
    addAcupoint,
    editAcupoint
  },
  data() {
    return {
      id: "",
      visible: this.modelValue,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
      type: "",
      medicineFilter: "",
      prescriptionType: "",
      addAcupointInfo:{},
      title: "",
      columns: [
        {
          title: "处方编号",
          key: "id",
          dataIndex: "id",
          width: "15%",
          slots: {
            customRender: "id"
          }
        },
        {
          title: "处方名称",
          key: "name",
          dataIndex: "name",
          width: "30%",
          slots: {
            customRender: "name",
          }
        },
        {
          title: "关联脉象",
          key: "pulse",
          dataIndex: "pulse",
          // ellipsis: true,
          width: "25%",
          slots: {
            customRender: "pulse"
          }
        },
        {
          title: "是否默认",
          key: "diseaseDefault",
          dataIndex: "diseaseDefault",
          // ellipsis: true,
          width: "10%",
          slots: {
            customRender: "diseaseDefault"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      prescription: "",
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      selectedRow: {},
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {},
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {
    showModal() {
      this.visible = true
    },
    editPrescription(record) {
      this.visible4 = true;
      this.selectedRow = { ...record, id: record.prescription.id, uuid: record.prescription.uuid, name: record.prescription.name }
    },
    addAcupoint() {
      this.addAcupointInfo = { category: this.record.category, name: this.record.name }
      console.log(this.addAcupointInfo);
      
      this.visible5 = true;
    },
    editAcupoint(record) {
      this.visible6 = true;
      this.selectedRow = { ...record, id: record.prescription.id, uuid: record.prescription.uuid, name: record.prescription.name }
    },
    async detail() {
      let obj = { diseaseId: this.record.id }

      obj.type = this.prescriptionType.type
      obj.category = this.prescriptionType.aiCategory

      let res = await Detail(obj);
      // console.log(res);

      if (res.errCode == 0) {
        this.data = res.data
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
      this.prescription = undefined
      // this.record = {}
    },
    async addToTable() {
      // let item = this.$store.state.prescriptionList.find(item => item.id == this.prescription)
      // this.data.items.push(item)
      if (this.data.find(item => item.prescriptionId == this.prescription)) {
        this.$message.info("该处方已存在")
        return
      }

      await Add({ prescriptionId: this.prescription, category: this.prescriptionType.aiCategory, diseaseId: this.record.id, })
      this.prescription = ""
      this.detail();
    },
    async callbackData(data) {
      // let item = this.$store.state.prescriptionList.find(item => item.id == data.id)
      // console.log(item);
      // this.data.items.push(data)
      console.log(data);
      if (this.data.find(item => item.prescriptionId == data.id)) {
        this.$message.error("该处方已存在")
        return
      }
      await Add({ prescriptionId: data.id, category: this.record.category, diseaseId: this.record.id })
      this.detail();
    },
    async callbackData2(data) {
      // let item = this.$store.state.prescriptionList.find(item => item.id == data.id)
      // console.log(item);
      // this.data.items.push(data)
      if (this.data.find(item => item.prescriptionId == data.id)) {
        this.$message.error("该处方已存在")
        return
      }
      await Add({ prescriptionId: data.id, category: this.record.category, diseaseId: this.record.id })
      this.detail();
    },
    async onDelete(record) {
      await Delete({ id: record.id })
      this.detail();
    }
  },
  watch: {
    record(val) {
      this.prescriptionType = this.$store.state.prescriptionType.find(item => item.aiCategory == val.category)
      this.title = this.prescriptionType.name + '：' + val.name
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5em;
}
</style>
