<template>
  <div>
    <div>
      <div class="searchArea">

        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->

        <a-input v-model:value="inputs.name" placeholder="病名" />
        <a-select v-model:value="inputs.gender" placeholder="性别" allowClear>
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option value="男">男</a-select-option>
          <a-select-option value="女">女</a-select-option>
        </a-select>
        <a-select v-model:value="inputs.isStandard" placeholder="标准化" allowClear>
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option :value="true">标准化</a-select-option>
          <a-select-option :value="false">非标准化</a-select-option>
        </a-select>

        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="showModal()">
          <template #icon>
            <PlusOutlined />
          </template>
          新增疾病
        </a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id" :pagination="{ pageSize:$store.state.tableSize ,total,current}" @change="search">

        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations">
              <span v-if="!editableData[record.id]">
                <a-popconfirm v-if="record.isStandard" title="是否确认取消标准化" @confirm="changeIsStandard(record)" okText="确认" cancelText="取消">
                  <a class="table_btn">非标准化</a>
                </a-popconfirm>
                <a-popconfirm v-if="!record.isStandard" title="是否确认标准化" @confirm="changeIsStandard(record)" okText="确认" cancelText="取消">
                  <a class="table_btn">标准化</a>
                </a-popconfirm>
              </span>
            </div>
            <div class="editable-row-operations flex_sa" style="width: 100px;">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations">
              <span>
                <a-popconfirm v-if="data.length" title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </div>

        </template>
        <template v-for="col in ['nameInitial']" #[col]="{ text, record }" :key="col">
          <div>
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template v-for="col in ['name']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" @change="changeDataNameInitial(record)" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template v-for="col in ['gender']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <!-- <a-input v-model:value="editableData[record.id][col]" /> -->
            <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" placeholder="性别" style="width:80px;text-align: center;">
              <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
              <a-select-option value="">不限</a-select-option>
              <a-select-option value="男">男</a-select-option>
              <a-select-option value="女">女</a-select-option>
            </a-select>
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template v-for="col in ['isStandard']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <div v-if="text">
              <check-circle-filled style="color:#389e0d;font-size:20px" />
            </div>
            <div v-else>
              <warning-filled style="color:#d4380d;font-size:20px" />
            </div>
          </div>
        </template>
        <template v-for="col in ['Jingfang']" #[col]="{ column,text, record }" :key="col">
          <div @click="getAutoPrescription(record,col)" class="clickable">
            {{record["jingfang"]}}
            <form-outlined style="color:#40a9ff;cursor: pointer;" />
          </div>
        </template>
        <template v-for="col in ['Mifang']" #[col]="{ column,text, record }" :key="col">
          <div @click="getAutoPrescription(record,col)" class="clickable">
            {{record["mifang"]}}
            <form-outlined style="color:#40a9ff;cursor: pointer;" />
          </div>
        </template>
        <template v-for="col in ['疾病穴位处方']" #[col]="{ column,text, record }" :key="col">
          <div @click="getAutoPrescription(record,col)" class="clickable">
            {{record["疾病穴位处方"]}}
            <form-outlined style="color:#40a9ff;cursor: pointer;" />
          </div>
        </template>
      </a-table>

    </div>

    <addDisease v-model="visible" />
    <getAutoPrescription v-model="visible2" :record="selectedRow" />

  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}
.offline {
  background: #979596;
  border-color: #979596;
}
:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}
.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}
.clickable {
  /* background-color: #000; */
  width: 100%;
  height: 100%;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  FormOutlined
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Add, Update, Delete } from "/src/api/disease.js";
import { pinyin } from 'pinyin-pro';
import getAutoPrescription from '/src/components/autoPrescription/getAutoPrescription.vue';
import addDisease from '/src/components/disease/addDisease.vue';
export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    getAutoPrescription,
    FormOutlined,
    addDisease
  },
  name: "疾病管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      visible2: false,
      active: undefined,
      id: undefined,
      selectedRow: {},
      total: 0,
      current: 1,
      // pageSize: 10,
      inputs: {
        name: undefined,
        gender: undefined,
        isStandard: undefined,
      },
      modelInput: {
        name: undefined,
        gender: undefined,
        nameInitial: undefined,
        isStandard: true,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      option: [{ value: "黄浦区" }, { value: "浦东新区" }, { value: "长宁区" }],
      columns: [
        {
          title: "病名",
          key: "name",
          dataIndex: "name",
          width: "25%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "首字母",
          key: "nameInitial",
          dataIndex: "nameInitial",
          ellipsis: true,
          width: "10%",
          slots: {
            customRender: "nameInitial"
          },
        },
        {
          title: "性别",
          width: "5%",
          key: "gender",
          dataIndex: "gender",
          slots: {
            customRender: "gender"
          }
        },
        {
          title: "标准化",
          key: "isStandard",
          dataIndex: "isStandard",
          // ellipsis: true,
          width: "10%",
          slots: {
            customRender: "isStandard"
          }
        },
        {
          title: "经方",
          key: "Jingfang",
          dataIndex: "Jingfang",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "Jingfang"
          }
        },
        {
          title: "秘方",
          key: "Mifang",
          dataIndex: "Mifang",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "Mifang"
          }
        },
        {
          title: "疾病穴位处方",
          key: "疾病穴位处方",
          dataIndex: "疾病穴位处方",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "疾病穴位处方"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {

    showModal() {
      this.visible = true;
    },
    closeModal(value) {
      this[value] = false;
    },
    getAutoPrescription(record, col) {
      this.visible2 = true
      record.category = col
      this.selectedRow = { ...record }
    },
    async handleOk() {
      let _this = this;

      let res = await Add(this.modelInput)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
      }
    },
    resetForm() {
      this.inputs = {
        name: undefined,
        gender: undefined,
        isStandard: undefined,
      }
    },
    async addDisease() {
      this.visible = true;
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let params = {
        id: this.editableData[id].id,
        name: this.editableData[id].name,
        nameInitial: this.editableData[id].nameInitial,
        gender: this.editableData[id].gender,
      }
      console.log(params);
      let res = await Update(params)
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        pageSize: this.$store.state.tableSize,
        includeMifang: 1,
        includeJingfang: 1,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }

      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data.records;
      } else {
        this.$message.error("查询失败", function () { });
      }
    },
    async changePage(pagination) {
      let _this = this;
      this.current = pagination.current;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        current: this.current,
        pageSize: this.$store.state.tableSize,
        includeMifang: 1,
        includeJingfang: 1,
      };

      let res = await Search(params)
      if (res.errCode == 0) {
        this.total = res.data.total;
        this.data = res.data.records
      } else {
        this.$message.error("查询失败", function () { });
      }
    },
    async changeIsStandard(record) {
      let res = await Update({ id: record.id, isStandard: !record.isStandard })
      if (res.errCode == 0) {
        record.isStandard = !record.isStandard;
      } else {
        this.$message.error("操作失败", function () { });
      }
    },

  }
};
</script>