<template>
  <div>
    <a-modal v-model:visible="visible" title="新增疾病" @ok="handleOk" okText="新建" cancelText="取消" :afterClose="closeModal">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-form-item label="病名">
          <a-input v-model:value="data.name" @change="changeNameInitial()" />
        </a-form-item>
        <a-form-item label="首字母">
          <a-input v-model:value="data.nameInitial" />
        </a-form-item>
        <a-form-item label="性别">
          <a-select v-model:value="data.gender" allowClear>
            <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
            <a-select-option value="">不限</a-select-option>
            <a-select-option value="男">男</a-select-option>
            <a-select-option value="女">女</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="标准化">
          <a-radio-group v-model:value="data.isStandard" option-type="button" :options="[{ label: '标准化', value: true }, { label: '非标准化', value: false }]" />
        </a-form-item>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Add } from "/src/api/disease.js";
import { pinyin } from 'pinyin-pro';
export default {
  components: {

  },
  data() {
    return {
      visible: this.modelValue,
      medicineFilter: "",
      columns: [
        {
          title: "名称",
          key: "itemName",
          dataIndex: "itemName",
          width: "30%",
          slots: {
            customRender: "itemName"
          }
        },
        {
          title: "用量",
          key: "quantity",
          dataIndex: "quantity",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "quantity"
          }
        },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          // ellipsis: true,
          width: "35%",
          slots: {
            customRender: "description"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      prescription: "",
      data: {
        name: undefined,
        gender: undefined,
        nameInitial: undefined,
        isStandard: true,
      },
      editableData: reactive({}),
      selectedRows: {},
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      editableData: reactive({}),
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    medicineList() {
      const list = this.$store.state.medicineList;
      if (!this.medicineFilter) {
        return list.slice(0, 200);
      }
      return list.filter(item => item.name?.toLowerCase().includes(this.medicineFilter) || item.nameInitial?.toLowerCase().includes(this.medicineFilter)).slice(0, 200);
    }
  },
  methods: {
    filterNameInitial(input) {
      this.medicineFilter = input?.toLowerCase();
    },
    filter() {
      return true
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      }

    },

    async handleOk() {
      let _this = this;
      let res = await Add(this.data)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.data = {
          name: undefined,
          gender: undefined,
          nameInitial: undefined,
          isStandard: true,
        }
      }

    },
    addToTable() {
      let item = this.$store.state.medicineList.find(item => item.id == this.prescription)
      if (this.data.items.find(element => element.medicineId == this.prescription)) {
        this.$message.info("已添加至列表", function () { });
        return
      }
      let id = item.id + 999999999
      let obj = { itemName: item.name, medicineId: item.id, id, quantity: undefined, description: undefined, type: "new" }
      this.data.items.push({ ...obj })
      this.editableData[id] = { ...obj }

      this.prescription = ""
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    getInitial(name) {
      let list = name.split("");
      let str = "";
      list.forEach(item => {
        str += pinyin(item, { toneType: 'none' }).split("")[0];
      });
      return str
    },
    changeNameInitial() {
      this.data.nameInitial = this.getInitial(this.data.name)
    },

  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
